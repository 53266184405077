.App {
  height: 100%;

  & > div:nth-child(1) {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;

    @media screen and (min-width: 768px) {
      justify-content: center;
    }
  }
  header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;
    width: 100%;

    .logo-container {
      display: flex;
      overflow: hidden;
      align-items: center;
      justify-content: center;
      height: 2rem;
    }

    .lang-container {
      align-self: flex-end;
    }
  }

  main {
    padding: 2rem;
    width: 100%;
    border-radius: 1rem;
    background-color: var(--card-bg);
  }
}
