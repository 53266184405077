.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

.centeredRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.idsContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1rem 0;
}
.idInner {
  display: flex;
  align-items: center;
}
.idInner > p {
  margin: 0 0 0 0.5rem;
}

.statusContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 2rem 0;
  padding: 2rem 1.5rem;
  background-color: #fff;
  min-width: 300px;
  border-radius: 8px;
}
.succesContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
}

.errorContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 1rem;
}
.signContainer {
  width: fit-content;
  background-color: #fff;
  border: 1px solid lightgray;
  border-radius: 8px;
  margin: 2rem 0;
  overflow: hidden;
  max-width: 500px;
}
