.document {
  overflow: auto;
  max-width: 120%;
  text-overflow: ellipsis;
  width: fit-content;
}

.rubricaePdfjsViewer {
  height: 800px;
  width: 100%;

  :global {
    * {
      box-sizing: initial;
    }

    #editorModeButtons {
      display: none;
    }
  }
}

@media screen and (max-width: 768px) {
  .rubricaePdfjsViewer {
    height: 420px;
  }
}