.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.documentsContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 0;
  width: 100%;
}

.form {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.checkboxesContainer {
  padding: 2rem 0;
}

.buttonsContainer {
  display: flex;
  padding: 1rem 0;
  gap: 1rem;
}
