@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

html {
  margin: 0;
  padding: 0;
}

body {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding: 1rem;
  min-height: 100vh;
  max-width: 1000px;
}

body > #root {
  flex-grow: 1;
  width: 100%;
}

* {
  box-sizing: border-box;
}


.react-pdf__Page {
  * {
    // overflow-x: scroll;
    // max-width: 100% !important;
    height: fit-content !important;
  }
}
