
.container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.idsContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1rem 0;
}
.idInner {
  display: flex;
  align-items: center;
}
.idInner > p {
  margin: 0 0 0 0.5rem;
}  
